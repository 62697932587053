import React from "react";
import { Container } from "react-bootstrap";
import trust_banner from "../../../assets/trust_banner.svg";
import Arrowblack from "../../../assets/Arrowblack.svg";
import styles from "../TrustBlock/trust.module.css";
import stylesG from "../../GlobalStyle/page.module.css";

const TrustBlock = (props) => {
  const {handleClick} =props;
  return (
    <Container fluid className={` ${styles.block_bg}`}>
      <div className="container">
        <div className="row row_align">
          

       

          <div className={`col-xl-5 col-lg-5 col-md-7 col-sm-12  order-md-last ${styles.order} ${styles.waterimg1}`}>
           <div className={`${styles.col_second_parent}`}>
           <h1 className={` ${styles.trust_hedding}`}>
              Trusted by thousands of users
            </h1>
            <p className={`${styles.trust_sub_hedding}`}>
              With the help of our <b>user-friendly, rapid, and secure platform</b>, we
              assisted<b> thousands of users</b> with loan requests.<b>Getpersonalloan
              process thousands of loan requests daily.</b> 
            </p>
           </div>
          </div>


          <div  className={`col-xl-7 col-lg-7 col-md-5 col-sm-12  ${styles.order}`}>
            <img src={trust_banner} alt="trust" className="img-fluid" />
          </div>

          </div>

         
        
      </div>
      <div className={`${stylesG.request_btn_main}`} onClick={handleClick}>
            <button className={`${stylesG.request_btn}`}>Request Loan With Trust
            <img src={Arrowblack} alt="arrow" className={` img-fluid ${stylesG.img_arrow}`}/>
            </button>
          </div>
    </Container>
  );
};

export default TrustBlock;
