import React, { useState } from 'react'
import { Container } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Footer from '../../Sections/Footer/Footer'
import Header from '../../Sections/Header/Header'
import ReadyToRequest from '../../Sections/ReadyToRequest/ReadyToRequest'
import styles from "../../GlobalStyle/page.module.css"
import { ErrorMessage } from '@hookform/error-message'
import { useForm } from 'react-hook-form'
import Api from '../../Service/Api'
import SuccesModal from '../../Modal/Success_Modal/SuccesModal'
const Unsubscribe = () => {

  const [showSuccess, setShowSuccess] = useState(false);
  const handleCloseSuccess = () => setShowSuccess(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const Urls = window.location.pathname.substring(1).replace();

  const websitename = "getpersonalloan.net"
  const onSubmit = async (data) => {
    try {
      let formData = {
        ...data, websitename,

      };
      console.log("submit Unssssssssss", formData);
      await Api.post("?c=home&m=unsub", formData);

      reset()
      setShowSuccess(!showSuccess)
    }
    catch (error) {
      console.error("error", error.message);
    }
  };


  return (
    <>
      <Container fluid>
        <Header />
        <div className={`container ${styles.block_padding}`}>

          <p className={`${styles.urls}`} ><Link to="/">Home {">"}</Link> {Urls} </p>
          <h1 className={`${styles.main_headding}`}>Unsubscribe</h1>


          <div className={`${styles.waterimg9}`}>
            <h1 className={`${styles.sub_headding}`} style={{ paddingTop: "0px" }}>You Will Be Missed</h1>

            <p className={`${styles.para}`}>
              We hate goodbyes but if you have a change of heart, we will always be here to search the loan option for you.
            </p>


            <div className={`${styles.form_parent}`}>
              <form
                className={`${styles.contact_inner_form}`}
                onSubmit={handleSubmit(onSubmit)}
              >


                <div className={`form-group  ${styles.outer_input_margin}`}>
                  <label className={`${styles.contact_lable}`}>Email Address</label>
                  <input
                    type="email"
                    className={`form-control ${styles.contact_input}`}
                    id="unsubmail"
                    name="unsubmail"
                    placeholder="Email Address"
                    {...register("unsubmail", {
                      required: "Email Address is required",

                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: "invalid email address",
                      },
                    })}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="unsubmail"
                    render={({ message }) => (
                      <p className="error_color">{message}</p>
                    )}
                  />
                </div>


                <div className={`${styles.contact_btn_main} ${styles.unsub_btn_main}`}>
                  <button type="submit" className={` ${styles.contact_btn_sub}`}>
                    Unsubscribe
                  </button>
                </div>
              </form>
            </div>
          </div>

        </div>
      </Container>
      <ReadyToRequest />
      <Footer />
      <SuccesModal show={showSuccess} handleClose={handleCloseSuccess} />
    </>
  )
}

export default Unsubscribe