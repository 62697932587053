import React from "react";
import { Container } from "react-bootstrap";
import styles from "../Steps/steps.module.css";
import stylesG from "../../GlobalStyle/page.module.css";
import stepimg1 from "../../../assets/stepimg1.svg";
import stepimg2 from "../../../assets/stepimg2.svg"
import stepimg3 from "../../../assets/stepimg3.svg"
import Arrowblack from "../../../assets/Arrowblack.svg";
export const Steps = (props) => {
  const {handleClick} =props;
  return (
    <Container fluid className="block_padding">
    
      <div className={`container ${styles.block_bg}`}>
        <div className="row row_align">
          <div className="col-lx-6 col-lg-6 col-md-6 col-sm-12">
            <div className={`${styles.frist_block_main}`}>
            
              <h1 className={`${styles.hedding_tex}`}>
                Seamlessly Request for loan
              </h1>
              <p className={`${styles.hedding_subtex}`}>
                You can submit a loan request in a handful of minutes whether
                you're working or just relaxing.
              </p>
            </div>
          </div>
          <div className="col-lx-6 col-lg-6 col-md-6 col-sm-12">
          <div className={`${styles.sec_block_main} ${styles.add_padding} `}>
              <div className={`${styles.steps_img}`}>
                <img src={stepimg3} alt="img1" />
              </div>
              <div>
                <h1 className={`${styles.sec_hedding_tex} `}>
                  Submit your information
                </h1>
                <p className={`${styles.sec_hedding_subtex}`}>
                  Send your information to lenders by filling out a quick and
                  secure loan request form.
                </p>
              </div>
            </div>

            <div className={`${styles.sec_block_main} ${styles.add_padding}`}>
              <div className={`${styles.steps_img}`}>
                <img src={stepimg1} alt="img1" />
              </div>
              <div>
                <h1 className={`${styles.sec_hedding_tex} `}>Quick Decision</h1>
                <p className={`${styles.sec_hedding_subtex}`}>
                  Our advanced system will respond to your request quickly.
                </p>
              </div>
            </div>

            <div className={`${styles.sec_block_main} ${styles.add_padding}`}>
              <div className={`${styles.steps_img}`}>
                <img src={stepimg2} alt="img1" />
              </div>
              <div>
                <h1 className={`${styles.sec_hedding_tex} `}>
                  Check your account
                </h1>
                <p className={`${styles.sec_hedding_subtex}`}>
                  If your request get accepted by the lenders' high-tech system,
                  the money could be transfered into your bank account as soon
                  as the next business day.
                </p>
              </div>
            </div>

            
          </div>
          <div className={`${stylesG.request_btn_main}`} onClick={handleClick}>
            <button className={`${stylesG.request_btn}`}>Request Seamlessly
            <img src={Arrowblack} alt="arrow" className={`${stylesG.img_arrow}`}/>
            </button>
          </div>
        </div>
      </div>
    </Container>
  );
};
