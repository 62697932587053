import React, { useRef } from 'react'
import { CheckBlock } from '../../Sections/CheckBlock/CheckBlock';
import { Faqs } from '../../Sections/Faqs/Faqs';
import Footer from '../../Sections/Footer/Footer';
import FristBlock from '../../Sections/FristBlock/FristBlock';
import Header from '../../Sections/Header/Header';
import ReadyToRequest from '../../Sections/ReadyToRequest/ReadyToRequest';
import { Steps } from '../../Sections/Steps/Steps';
import TrustBlock from '../../Sections/TrustBlock/TrustBlock';
// import { CheckBlock } from '../../Sections/CheckBlock/CheckBlock';
// import { Faqs } from '../../Faqs/Faqs';
// import Footer from '../../Sections/Footer/Footer';

// import FristBlock from '../../Sections/FristBlock/FristBlock'
// import Header from '../../Header/Header'
// import ReadyToRequest from '../../Sections/ReadyToRequest/ReadyToRequest';
// import { Steps } from '../../Sections/Steps/Steps';
// import TrustBlock from '../../Sections/TrustBlock/TrustBlock';


const Home = () => {

  const ref = useRef(null);

  const handleClick = () => {
    ref.current?.scrollIntoView({behavior: 'smooth'});
  };

  return (
   <>
     <Header/>
    <div ref={ref}>
    <FristBlock  />
    </div>
     <TrustBlock handleClick={handleClick}/>
   <Steps handleClick={handleClick}/>
   <CheckBlock handleClick={handleClick}/>
    <Faqs handleClick={handleClick}/>
   <ReadyToRequest handleClick={handleClick}/>
    <Footer/> 
   </>
  )
}

export default Home