import React from 'react'
import { Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import styles from "../../GlobalStyle/page.module.css"
import Footer from '../../Sections/Footer/Footer'
import Header from '../../Sections/Header/Header'
import ReadyToRequest from '../../Sections/ReadyToRequest/ReadyToRequest'
const RatesFees = () => {

  const Urls = window.location.pathname.substring(1).replace();

  return (
    <>
      <Header />
      <Container fluid className={`${styles.block_bg}`}>
        <div className={`container ${styles.block_padding} `}>
          <p className={`${styles.urls}`} ><Link to="/">Home {">"}</Link> {Urls} </p>
          <h1 className={`${styles.main_headding}`}>Rates & Fees</h1>

          <div className={`${styles.waterimg4}`}>
            <h1 className={`${styles.sub_headding}`} style={{ paddingTop: "0px" }}>We are focused on transparency so you know exactly where you stand at all times.</h1>

            <div className={`${styles.para_parent}`}>
              <div className={`${styles.parenr_inner}`}>
                <h1 className={`${styles.subtext_small}`}>getpersonalloan.net has no control over loan rates</h1>
                <p className={`${styles.para}`}>The rates and fees pertaining to your funds will eventually be controlled by your lender. It is the objective of lendfy.net to assure that you will deal with those lenders who comply with the Federal and state lending rules. Along the same lines, we give a lot of information to enable you to tackle any issues that may emerge with the lender you select.</p>
              </div>

              <div className={`${styles.parenr_inner}`}>
                <h1 className={`${styles.subtext_small}`}>There are repercussions of late payments or default payments</h1>
                <p className={`${styles.para}`}>The most critical thing that you should comprehend as a borrower is an implication of making late installments on your credit. Although every lender whom we work with has its own particular terms and conditions for the advances they offer, however, there are some late-installment repercussions that you ought to know before entering into a loan contract with the lender.</p>
              </div>

              <div className={`${styles.parenr_inner}`}>
                <h1 className={`${styles.subtext_small}`}>Perusal of clauses in the contract is must</h1>
                <p className={`${styles.para}`}>It is vital to comprehend the penalties and installment choices for the particular lender that you are associated with and to ensure that you totally comprehend their policies.</p>
              </div>
            </div>
          </div>

        </div>
        <Row>
          <ReadyToRequest />
        </Row>
      </Container>
      <Footer />
    </>
  )
}

export default RatesFees