import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";
import { createSearchParams, Link, useNavigate } from "react-router-dom";
import logoimg from "../../../assets/RFClogo.svg";
import Arrowblack from "../../../assets/Arrowblack.svg";
import login_img from "../../../assets/login.svg";
import styles from "./header.module.css";
import { Squeeze } from 'hamburger-react'
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import useQuery from "../FristBlock/useQuery";

function Header() {
  const { handleSubmit } = useForm();
  // Get query Params
  const query = useQuery();
  // const queryName = query.getAll;
  const uid = query.get("utm_source") || "";
  const utm_campaign = query.get("utm_campaign") || "";
  const utm_medium = query.get("utm_medium") || "";
  const s2 = query.get("s2") || "";
  const s3 = query.get("s3") || "";
  const s4 = query.get("s4") || "";

  const params = { s1: utm_campaign, s2: s2, s3: s3, s4: s4, uid: uid, utm_medium: utm_medium };
  console.log("params", params);

  const navigate = useNavigate();
  // navigate('/form');

  const onSubmit = (data) => {
    navigate({
      pathname: "/form",
      search: `?${createSearchParams(params)}`,
    });
  };

  const domain = window.location.host;
  console.log("Domain Name", domain);

  const links = [
    // {
    //   id: 1,
    //   text: "Home",
    //   url: "/"
    // },
    {
      id: 1,
      text: "About Us",
      // color: "#1D1D1FE0",
      url: "/about-us"
    },
    {
      id: 2,
      text: "FAQs",
      // color: "#1D1D1FE0",
      url: "/faqs",
    },
    {
      id: 3,
      text: "Rate & fees",
      // color: "#1D1D1FE0",
      url: "/rates-fees",
    },
  ];
  const [show, setShow] = useState(false);

  const [title, setTitle] = useState();

  console.log("Title", title);

  const handalClick = (event) => {

    setTitle(event.target.innerText);
    setShow(!show);
  };
  // const handleClose = () => setShow(false);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      {["lg"].map((expand) => (
        <>
          <Navbar key={expand} expand={expand} className={`${styles.nav_bg}`}>
            <Container fluid>
              <div className="container p-0">
                <div className={`${styles.nav_parent}`} >
                  <Navbar.Brand
                    className={`${styles.logoCenterText2}`}
                  >
                  
                   <Link to="/">
                   <img
                      src={logoimg}
                      alt="headerlogo"
                      className={`${styles.header_logo}`}
                    />
                   </Link>

                  </Navbar.Brand>
                  <Navbar.Toggle
                    aria-controls={`offcanvasNavbar-expand-${expand}`}
                    className={`${styles.Hamburger_boder}`}
                  >

                    <div className={`${styles.Login_bar_main}`}>
                      <Form
                        className={`${styles.but_nav_start}`}
                        onSubmit={handleSubmit(onSubmit)}
                      >
                        <button
                          className={`${styles.get_start_btn}`}
                          type="submit"
                        >
                          Log In
                          <img
                            src={login_img}
                            alt="login"
                            className={`img-fluid ${styles.log_inimg}`}
                          />
                        </button>
                      </Form>


                      <Squeeze direction="right" size={20} color="#1D1D1F" />
                    </div>
                  </Navbar.Toggle>

                  <Navbar.Offcanvas
                    id={`offcanvasNavbar-expand-${expand}`}
                    aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                    placement="end"
                    className="button button-effect offcanvas-top"
                  >
                    <Offcanvas.Body className={`${styles.contact_bg}`}>
                      <Nav
                        className={`justify-content-end flex-grow-1 ${styles.ulstylink}`}
                      >
                        {links.map((link, i) => (
                          <Link
                            className={`${styles.header_link}`}
                            style={{ color: link.color }}
                            onClick={handalClick}
                            key={i}
                            to={link.url}
                          >
                            {link.text}
                          </Link>
                        ))}

                        <div className={`${styles.mob_btn_main}`}>
                          <Form
                            className={`${styles.but_nav_start}`}
                            onSubmit={handleSubmit(onSubmit)}
                          >
                            <button className={`${styles.mob_btn_sty}`}>Request Loan <img src={Arrowblack} alt="arrow" className={` img-fluid ${styles.img_arrow}`} /></button>
                          </Form>
                        </div>

                        {/* <Link
                            className={`${styles.header_link}`}
                            style={{ color: '#1D1D1FE0 '}}
                            onClick={handalClick}
                           
                            to="/"
                          >
                           Home
                          </Link>
                        <Link
                            className={`${styles.header_link}`}
                            style={{ color: '#1D1D1FE0 '}}
                            onClick={handalClick}
                           
                            to="/privacy-policy"
                          >
                            About Us
                          </Link>
                          <Link
                            className={`${styles.header_link}`}
                            style={{ color: '#1D1D1FE0 '}}
                            onClick={handalClick}
                            to="/faqs"
                          >
                          FAQs
                          </Link>
                          <Link
                            className={`${styles.header_link}`}
                            style={{ color: '#1D1D1FE0 '}}
                            onClick={handalClick}
                            to="/rates-fees"
                          >
                            Rate & fees
                          </Link>
                         */}

                      </Nav>
                      <Form
                        className={`${styles.but_nav_start} ${styles.but_display}`}
                        onSubmit={handleSubmit(onSubmit)}
                      >
                        <button
                          className={`${styles.get_start_btn}`}
                          type="submit"
                        >
                          Log In 
                          <img
                            src={login_img}
                            alt="login"
                            className={`img-fluid ${styles.login_img}`}
                          />
                        </button>
                      </Form>
                    </Offcanvas.Body>
                  </Navbar.Offcanvas>
                </div>
              </div>
            </Container>
          </Navbar>
        </>
      ))}

      {/* {title === "How it Work" && (
        <HowitworkModal show={show} handleClose={handleClose} domain={domain} />
      )}
      {title === "Rates & Fees" && (
        <RatsfeesModale show={show} handleClose={handleClose}   domain={domain}/>
      )}
      {title === "Contact Us" && (
        <ContactModal show={show} handleClose={handleClose}  domain={domain}/>
      )} */}
    </>
  );
}

export default Header;
