import React, { useState } from 'react'
import { Container } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Footer from '../../Sections/Footer/Footer'
import Header from '../../Sections/Header/Header'
import ReadyToRequest from '../../Sections/ReadyToRequest/ReadyToRequest'
import styles from "../../GlobalStyle/page.module.css"
import { useForm } from 'react-hook-form'
import Api from '../../Service/Api'
import { ErrorMessage } from '@hookform/error-message'
import SuccesModal from '../../Modal/Success_Modal/SuccesModal'

const ContactUs = () => {
  const [showSuccess, setShowSuccess] = useState(false);
  const handleCloseSuccess = () => setShowSuccess(false);
  const {
    handleSubmit,
    reset,
    register,
    formState: { errors },
  } = useForm();
  const Urls = window.location.pathname.substring(1).replace();

  const websitename ="getpersonal.com"

  const onSubmit = async (data) => {
    try {
      let formData = {
        ...data,websitename,
      };
      console.log("submit Contect Data", formData);
      await Api.post("?c=home&m=contactUs", formData);
   
      reset()
      setShowSuccess(!showSuccess)
   
    } catch (error) {
      console.error("error", error.message);
    }
  };
  return (
    <>
      <Container fluid>
        <Header />
        <div className={`container ${styles.block_padding}`}>

          <p className={`${styles.urls}`} ><Link to="/">Home {">"}</Link> {Urls} </p>
          <h1 className={`${styles.main_headding}`}>Contact Us</h1>

          {/* <div className={`${styles.para_parent}`}>
          <p className={`${styles.para}`}><b>Getpersonalloan</b> is not a moneylender and does not make advances or credit choices. <b>Getpersonalloan</b> merely gives a connecting service and is not a delegate, operator, or correspondent for any specialist organization or lender. You are under no compulsion to use <b>Getpersonalloan’s</b> service to contact with, fill credit from, or accept any loan offer from any service provider or lender. This service is not available in all states.</p>

          <p className={`${styles.para}`}><b>Getpersonalloan</b> doesn't charge any fee for submitting a loan request. <b>Getpersonalloan</b> is not a loan giver and does not lend money. In light of the data you submit, <b>Getpersonalloan</b> will look through its huge network of lenders keeping in mind the end goal to connect you with a loan provider that might have the capacity to serve your requirements.</p>
          <p className={`${styles.para}`}>In the event that your loan request is acknowledged by a loan provider, you will be diverted to an e-signature page on the moneylender's site. All expenses and rate data charged by the moneylender ought to be disclosed to you around then. You should thoroughly understand the charges and rate information given by the money lender before consenting to the terms of the credit or signing any official records. You are under no commitment to acknowledge the terms.</p>
          <p className={`${styles.para}`}>In the event that you decide that the advance terms given by the lender are satisfactory and wish to continue, you may e-signature the record proving your concurrence with those terms. After signing, you will go into a lawfully binding contract with the loan provider and will be in charge of all risk related to the agreement. Your legally binding commitments are to the loan providers, and not to getpersonalloan.net The loan cost and loan fees you consent to are set exclusively by the moneylender and not getpersonalloan.net</p>
          <p className={`${styles.para}`}>getpersonalloan.net does not ensure that your advance request will be accepted by a lender or other specialist organization. In case that you are offered a loan, getpersonalloan.net does not ensure that the charges and rates will be adequate to you or that your advance will be subsidized. All choices relating to your loan are the moneylenders' choices, not <b>Getpersonalloan’s.</b></p>
          <p className={`${styles.para}`}>
            Loan terms may vary depending on the lender that you are connected with. This website does not constitute an offer or solicitation to lend. We at <b>Getpersonalloan</b> try our best to connect you with the most suitable lender, however approval for funding/loan is not guaranteed and is fully determined by the lender. The form on the website will not be considered as an application for a loan and <b>Getpersonalloan</b> will share PII with third-parties for the purpose of connecting consumers with lenders. Third-party provider may conduct credit checks related to the user's request. <b>Getpersonalloan</b> will not charge any fee or remuneration from the user of this website, however the operator of this website is compensated by lenders for connecting consumers with lenders.
          </p>

        </div> */}
        <p className={`${styles.para} `}>
        If you have a question, comment, or opinion about anything on this site, or if you’d like to submit a review or complaint about a lender, please use the form below. We appreciate any and all feedback, and we respect your privacy.
          </p>


          <div className={`${styles.form_parent} `}>
            <form
              className={`${styles.contact_inner_form} ${styles.waterimg6} `}
            onSubmit={handleSubmit(onSubmit)}
            >
              <div className="d-flex justify-content-between">
                <div  className={`form-group  ${styles.outer_input_margin}`} >
                  <label className={`${styles.contact_lable}`}>First Name</label>
                  <input
                    className={`form-control ${styles.contact_input}`}
                    id="firstname"
                    placeholder="First Name"
                    name="firstname"
                  {...register("firstname", {
                    required: "This input is required.",

                  minLength: {
                      value: 3,
                      message:
                        "minmum length ",
                    },
                  })}
                  />
                  <ErrorMessage
                  errors={errors}
                  name="firstname"
                  render={({ message }) => (
                    <p className="error_color">{message}</p>
                  )}
                />
                </div>
                <div className={`form-group  ${styles.outer_input_margin}`}>
                  <label className={`${styles.contact_lable}`}>Last Name</label>
                  <input
                    type="text"
                    className={`form-control ${styles.contact_input}`}
                    id="lastname"
                    placeholder="Last Name"
                    name="lastname"
                  {...register("lastname", {
                    required: "This input is required.",
                  })}
                  />

                  <ErrorMessage
                  errors={errors}
                  name="lastname"
                  render={({ message }) => (
                    <p className="error_color">{message}</p>
                  )}
                />
                </div>
              </div>

              <div className={`form-group  ${styles.outer_input_margin}`}>
                <label className={`${styles.contact_lable}`}>Email Address</label>
                <input
                  type="email"
                  className={`form-control ${styles.contact_input}`}
                  id="email"
                  name="email"
                  placeholder="Email Address"
                {...register("email", {
                  required: "Email Address is required",

                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "invalid email address",
                  },
                })}
                />
                <ErrorMessage
                errors={errors}
                name="email"
                render={({ message }) => (
                  <p className="error_color">{message}</p>
                )}
              />
              </div>

              <div className={`form-group  ${styles.outer_input_margin}`}>
                <label className={`${styles.contact_lable}`}>Message</label>
                <textarea
                 className={`form-control ${styles.contact_input}`}
                  type="text"
                  name="message"
                  id="message"
                  rows="2"
                  placeholder="Message"
                {...register("message", {
                  required: "This input is required.",
                   minLength: {
                        value: 10,
                        message:
                          "Minimum 10 characters",
                      },

                })}
                ></textarea>
                <ErrorMessage
                errors={errors}
                name="message"
                render={({ message }) => (
                  <p className="error_color">{message}</p>
                )}
              />
              </div>
              <div  className={`${styles.contact_btn_main}`}>
                <button type="submit"  className={`${styles.contact_btn_sub}`}>
                Submit
                </button>
              </div>
            </form>

            <div className={`${styles.info_main}`}>
            <p className={`  ${styles.para} ${styles.infopading}`}>
            <strong>Our Address  : </strong>
              13920 Landstar Blvd Suite#101 – 0361 Orlando FL , 32824
            </p>

            <p className={` ${styles.para}`}>
              <strong>Phone : </strong>
              <a className={`  ${styles.para} ${styles.infopading}`} href="tel:+1844-368-6075">
                +1-844-368-6075
              </a>
            </p>
            <p className={`  ${styles.para}`}>
              <strong>Email : </strong>
              <a className={`${styles.para}`} href="mailto:info@getpersonalloan.net">
                info@getpersonalloan.net
              </a>
            </p>
          </div>
          </div>
        </div>
      </Container>
      <ReadyToRequest />
      <Footer />
      <SuccesModal show={showSuccess} handleClose={handleCloseSuccess}/>
    </>
  )
}

export default ContactUs