import React from 'react'
import { Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import styles from "../../GlobalStyle/page.module.css"
import Footer from '../../Sections/Footer/Footer'
import Header from '../../Sections/Header/Header'
import ReadyToRequest from '../../Sections/ReadyToRequest/ReadyToRequest'
const Econsent = () => {

    const Urls = window.location.pathname.substring(1).replace();

    return (
        <>
            <Header />
            <Container fluid className={`${styles.block_bg}`}>
                <div className={`container ${styles.block_padding}`}>
                    <p className={`${styles.urls}`} ><Link to="/">Home {">"}</Link> {Urls} </p>
                    <h1 className={`${styles.main_headding}`}>E-Consent</h1>

                    <div className={`${styles.para_parent} ${styles.waterimg7}`}>
                        <div className={`${styles.parenr_inner}`}>
                            <h1 className={`${styles.subtext_small}`} style={{ paddingTop: "0px" }}>
                                {/* Agreement and Acknowledgment */}
                            </h1>
                            <p className={`${styles.para}`}>IT IS TO BE NOTED THAT THIS CONSENT IS REQUIRED BY LENDERS WITH WHOM YOU WILL BE CONNECTED. THIS CONSENT DOES NOT BY ANY MEANS BRING THE OWNER OF THE WEBSITE INTO AN AGREEMENT (BETWEEN YOU AND OWNER) THAT IS GOVERNED BY THE COURT OF LAW. THE OWNER OF THIS WEBSITE DOES NOT GUARANTEE OR ASSURES ANYTHING AFTER THIS CONSENT COMES INTO EFFECT. THE OWNER OF THIS WEBSITE GENERALLY AND SPECIFICALLY DISCLAIMS ANY AND ALL LIABILITY FOR USE OF THIS CONSENT DOCUMENT.
                                Please read this information carefully and print a duplicate and/or retain this information for future reference.</p>
                        </div>

                        <div className={`${styles.parenr_inner}`}>
                            <h1 className={`${styles.subtext_small}`}>Introduction</h1>
                            <p className={`${styles.para}`}>In order to offer you a loan, one or more of the third party lenders need your consent to use and accept electronic signatures, records, and disclosures ("E-Consent"). This form from such lender notifies you of your rights when receiving electronic disclosures, notices, and information. By tapping on the link/ button assenting to this notice, you acknowledge that you received this E-Consent and that you consent to direct transactions utilizing electronic signatures, electronic disclosures, electronic records, and electronic contract documents ("Disclosures").</p>
                        </div>

                        <div className={`${styles.parenr_inner}`}>
                            <h1 className={`${styles.subtext_small}`}>Option for Paper or Non-Electronic Records</h1>
                            <p className={`${styles.para}`}>You may request any Disclosures in paper by contacting the third party lender directly. The lenders will provide paper copies at no charge.</p>
                        </div>

                        <div className={`${styles.parenr_inner}`}>
                            <h1 className={`${styles.subtext_small}`}>The scope of Consent</h1>
                            <p className={`${styles.para}`}>The lender has informed us that this E-Consent applies to all interactions online concerning you and the third party lender and includes those interactions engaged in on any mobile device, including phones, advanced mobile phones, and tablets. By exercising this E-Consent, the third party lender may process your information and interact amid every single online interaction with you electronically. The lender may likewise send you notices electronically related to its interactions and transactions. Disclosures might be provided online at our or third-party lenders' websites and might be provided by e-mail.</p>
                        </div>

                        <div className={`${styles.parenr_inner}`}>
                            <h1 className={`${styles.subtext_small}`}>Consenting to Do Business Electronically</h1>
                            <p className={`${styles.para}`}>Before you decide to work together electronically with the third party lenders, you ought to consider whether you have the required hardware and software capabilities described below.</p>
                        </div>

                        <div className={`${styles.parenr_inner}`}>
                            <h1 className={`${styles.subtext_small}`}>Hardware and Software Requirements</h1>
                            <p className={`${styles.para}`}>
                                To access and retain the Disclosures and Communications electronically, you will need to use the following computer software and hardware:
                                <ul>
                                    <li>Internet access with 128-bit encryption</li>
                                    <li>Adobe Acrobat Reader 6 or higher</li>
                                    <li>Sprint Corporation</li>
                                    <li>Ability to Print</li>
                                    <li>Internet Explorer 8 or higher</li>
                                    <li>Email Acces</li>
                                    <li>Firefox version 3.6 or higher</li>
                                </ul>
                            </p>
                        </div>

                        <div className={`${styles.parenr_inner}`}>
                            <h1 className={`${styles.subtext_small}`}>Withdrawing Consent</h1>
                            <p className={`${styles.para}`}>Your E-Consent for our third-party lenders' consideration of your coordinating request can't be pulled back because it is a one-time transaction. If you are connected with one or more third-party lenders, you are free to pull back your E-Consent with those third-party lenders whenever and at no charge. However, in the event that you pull back this E-Consent before receiving credit, you might be prevented from acquiring credit from that lender. Contact the third party lender directly on the off chance that you wish to pull back this E-consent. If you decide to pull back this E-Consent, the legal effectiveness, legitimacy, and enforceability of prior electronic Disclosures won't be affected.
                                Change to Your Contact Information. You should keep third-party lenders informed of any change in your electronic address or mailing address. You may update such information by logging into the third party lender's website or by sending the lender a written update via mail.</p>
                        </div>


                        <div className={`${styles.parenr_inner}`}>
                        <h1 className={`${styles.subtext_small}`}>YOUR ABILITY TO ACCESS DISCLOSURES</h1>
                        <p className={`${styles.para}`}>BY CLICKING THE LINK, YOU ASSENT TO THE TERMS OF THIS DISCLOSURE YOU ACKNOWLEDGE THAT YOU CAN ACCESS THE DISCLOSURES IN THE DESIGNATED FORMATS DESCRIBED ABOVE.</p>
                    </div>


                    <div className={`${styles.parenr_inner}`}>
                        <h1 className={`${styles.subtext_small}`}>CONSENT</h1>
                        <p className={`${styles.para}`}>BY CLICKING THE LINK,YOU ACKNOWLEDGE YOU HAVE READ THIS INFORMATION ABOUT ELECTRONIC SIGNATURES, RECORDS, DISCLOSURES, AND DOING BUSINESS ELECTRONICALLY. YOU CONSENT TO USING ELECTRONIC SIGNATURES, HAVING ALL DISCLOSURES PROVIDED OR MADE AVAILABLE TO YOU IN ELECTRONIC FORM AND TO DOING BUSINESS WITH THE LENDER ELECTRONICALLY. YOU ACKNOWLEDGE THAT YOU MAY REQUEST A PAPER COPY OF THE ELECTRONIC RECORDS AND DISCLOSURES, WHICH WILL BE PROVIDED TO YOU AT NO CHARGE. IF YOU REFRAIN FROM PROCEEDING THEN YOU NEITHER WISH TO USE ELECTRONIC SIGNATURES NOR CONDUCT THIS TRANSACTION ELECTRONICALLY. YOU ALSO ACKNOWLEDGE THAT YOUR CONSENT TO ELECTRONIC DISCLOSURES IS REQUIRED TO RECEIVE SERVICES FROM THIRD PARTY LENDERS OVER THE INTERNET.</p>
                    </div>

                    </div>

              

                </div>
                <Row>
                    <ReadyToRequest />
                </Row>
            </Container>
            <Footer />
        </>
    )
}

export default Econsent