import React from 'react'
import { Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import styles from "../../GlobalStyle/page.module.css"
import Footer from '../../Sections/Footer/Footer'
import Header from '../../Sections/Header/Header'
import ReadyToRequest from '../../Sections/ReadyToRequest/ReadyToRequest'
const TermService = () => {

  const Urls = window.location.pathname.substring(1).replace();

  return (
    <>
      <Header />
      <Container fluid className={`${styles.block_bg}`}>
        <div className={`container ${styles.block_padding}`}>
          <p className={`${styles.urls}`} ><Link to="/">Home {">"}</Link> {Urls} </p>
          <h1 className={`${styles.main_headding}`}>Terms of Service</h1>

          <div className={`${styles.para_parent} ${styles.waterimg7}`}>
            <div className={`${styles.parenr_inner}`}>
              <h1 className={`${styles.subtext_small}`} style={{ paddingTop: "0px" }}>Agreement and Acknowledgment</h1>
              <p className={`${styles.para}`}>Getpersonalloan doesn't charge any fee for submitting a loan request. getpersonalloan.net is not a loan giver and does not lend money. In light of the data you submit, getpersonalloan.net will look through its huge network of lenders keeping in mind the end goal to connect you with a loan provider that might have the capacity to serve your requirements.</p>
            </div>

            <div className={`${styles.parenr_inner}`}>
              <h1 className={`${styles.subtext_small}`}>Acceptance of offer and E-Signature</h1>
              <p className={`${styles.para}`}>In the event that your loan request is acknowledged by a loan provider, you will be diverted to an e-signature page on the moneylender's site. All expenses and rate data charged by the moneylender ought to be disclosed to you around then. You should thoroughly understand the charges and rate information given by the money lender before consenting to the terms of the credit or signing any official records. You are under no commitment to acknowledge the terms.
                If you decide that the advance terms given by the lender are satisfactory and wish to continue, you may e-signature the record proving your concurrence with those terms. After signing, you will go into a lawfully binding contract with the loan provider and will be in charge of all risk related to the agreement. Your legally binding commitments are to the loan providers, and not to getpersonalloan.net.</p>
            </div>

            <div className={`${styles.parenr_inner}`}>
              <h1 className={`${styles.subtext_small}`}>Loan Acceptance Policy & Rates</h1>
              <p className={`${styles.para}`}>getpersonalloan.net does not ensure that your advance request will be accepted by a lender or other specialist organization. In case that you are offered a loan, getpersonalloan.net does not ensure that the charges and rates will be adequate to you or that your advance will be subsidized. All choices relating to your loan are the moneylenders' choices, not getpersonalloan.net's</p>
            </div>

            <div className={`${styles.parenr_inner}`}>
              <h1 className={`${styles.subtext_small}`}>Compliance With Laws</h1>
              <p className={`${styles.para}`}>You agree to comply with all applicable laws, statutes, ordinances and regulations regarding your use of the Site and your acceptance or purchase of products or services through this Site's third party vendors.</p>
            </div>

            <div className={`${styles.parenr_inner}`}>
              <h1 className={`${styles.subtext_small}`}>Credit Check</h1>
              <p className={`${styles.para}`}>As stated, this website is not a party to the contract and it presents your loan request to a pool of lenders who may check your credit score and history for the purpose of accepting your loan request. It is wholly and solely at the discretion of lenders to approve your loan(as they may deem fit) and they are in no way answerable for rejecting a loan request to the applicants or to the getpersonalloan.net.</p>
            </div>

            <div className={`${styles.parenr_inner}`}>
              <h1 className={`${styles.subtext_small}`}>SMS and Auto Dialed Calls</h1>
              <p className={`${styles.para}`}>By entering your contact information into getpersonalloan.net, you expressly request to receive information via telephone call, email and/or SMS/text message, including through the use of an automatic telephone dialing system or artificial/prerecorded voice, SMS/text message, even if your telephone number is listed on any internal, state or federal Do-Not-Call registry list. You may opt-out by replying<b>"STOP"</b> to any message that you receive and you may requested additional assistance by replying <b>"HELP"</b> . Up to 15 recurring monthly messages per month. Msg & Data rates may apply. T-Mobile® is not liable for delayed or undelivered messages. This service is provided through <b>Short Code: 34676</b></p>
            </div>

            <div className={`${styles.parenr_inner}`}>
              <h1 className={`${styles.subtext_small}`}>Supported Carriers</h1>
              <p className={`${styles.para}`}>

                <ul>
                  <li>AT&T Mobility</li>
                  <li>Verizon Wireless</li>
                  <li>Sprint Corporation</li>
                  <li>T- Mobile & Metro PCS</li>
                  <li>US Cellular</li>
                  <li>Virgin Mobile</li>
                  <li>Google Voice</li>
                  <li>Truphone Limited</li>
                  <li>Open Mobile Puerto Rico</li>
                  <li>Claro Puerto Rico</li>
                  <li>Peerless Network</li>
                  <li>Interop(Tier 2/3)</li>
                </ul>
              </p>
            </div>

          </div>

        </div>
        <Row>
          <ReadyToRequest />
        </Row>
      </Container>
      <Footer />
    </>
  )
}

export default TermService