import React from 'react'
import { Accordion, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import styles from "../../GlobalStyle/page.module.css"
import Footer from '../../Sections/Footer/Footer'
import Header from '../../Sections/Header/Header'
import ReadyToRequest from '../../Sections/ReadyToRequest/ReadyToRequest'

const Faq = () => {

  const Urls = window.location.pathname.substring(1).replace();

  return (
    <>
      <Header />
      <Container fluid className={`${styles.block_bg}`}>
        <div className={`container ${styles.block_padding}`}>
          <p className={`${styles.urls}`} ><Link to="/">Home {">"}</Link> {Urls} </p>
          <h1 className={`${styles.main_headding}`}>Frequently asked<br /> questions</h1>

          <div className="row">


            <div className={`faq_parent faqpage  ${styles.faq_main}`} >
              <Accordion flush>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>
                    <p className={`${styles.faqs_quse_text}`}> Does it cost anything to use Getpersonalloan?</p>
                  </Accordion.Header>
                  <Accordion.Body>
                  
                    <p>- Should be employed in your current position for at least 90 days</p>
                    <p>- Should be older than 18 years of age, should be a US citizen or a permanent
                      resident</p>
                    <p>- Should have a minimum income of $1000 each month after tax deductions</p>
                    <p>- Should have a valid email address, work and home phone number.</p>
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="2">
                  <Accordion.Header>
                    <p className={`${styles.faqs_quse_text}`}> What amount could I get?</p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>You could be eligible to any amount up to $35000. The approved amount will be determined by the lender. Having a working email address, work telephone number and home telephone are some of the usual prerequisites for getting a loans</p>
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="3">
                  <Accordion.Header>
                    <p className={`${styles.faqs_quse_text}`}>  How much time does it take? </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>It could take as few minutes to process the duly filled form. You could get your loans after approval within the next business day.</p>
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="4">
                  <Accordion.Header>
                    <p className={`${styles.faqs_quse_text}`}>  What is the cost?</p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>There is no cost to use lendfy.net. The fees of the loan will vary depending upon the amount and the lender. The lender will inform you of the fees for the loan which may vary depending on the lender.</p>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>

          </div>

        </div>





        <Row>
          <ReadyToRequest />
        </Row>
      </Container>
      <Footer />
    </>
  )
}

export default Faq