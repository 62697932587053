import React from 'react'
import { Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import styles from "../../GlobalStyle/page.module.css"
import Footer from '../../Sections/Footer/Footer'
import Header from '../../Sections/Header/Header'
import ReadyToRequest from '../../Sections/ReadyToRequest/ReadyToRequest'
const Sellinfo = () => {

  const Urls = window.location.pathname.substring(1).replace();

  return (
    <>
      <Header />
      <Container fluid className={`${styles.block_bg}`}>
        <div className={`container ${styles.block_padding} `}>
          <p className={`${styles.urls}`} ><Link to="/">Home {">"}</Link> {Urls} </p>
          <h1 className={`${styles.main_headding}`}>Do not sell my information</h1>

          <h1 className={`${styles.sub_headding}`} style={{ paddingTop: "0px" }}>Your Rights Under the CCPA.</h1>

          <div className={`${styles.para_parent} ${styles.waterimg10}`}>
            <div className={`${styles.parenr_inner}`}>
              <h1 className={`${styles.subtext_small}`}>General Information</h1>
              <p className={`${styles.para}`}>If you are a California Resident, you are entitled to additional rights over your personal information under the California Consumer Privacy Act of 2018 (CCPA) and certain other privacy and data protection laws. This section summarizes the categories of information we collect about you, why the infsormation is collected, who we share your information with, and for what reason that information is shared.</p>
            </div>

            <div className={`${styles.parenr_inner}`}>
              <h1 className={`${styles.subtext_small}`}>Categories of Personal Information We Collect</h1>
              <p className={`${styles.para}`}>We collect information that identifies you, your household or your device or is reasonably capable of being connected with or linked to you, your household, or your device ("Personal Information"). Personal information does not include public information available from government records, de-identified or aggregated information or information that is protected by certain laws such as HIPAA for health-related information and the Gramm-Leach Bliley Act (GLBA) for certain financial information.</p>
            </div>

            <div className={`${styles.parenr_inner}`}>
              <h1 className={`${styles.subtext_small}`}>We collect the following categories of Personal Information:</h1>
              <p className={`${styles.para}`}><b>Identifiers</b> - First and last name, Mailing address (including length at address), Telephone number (including best time to call), Email address, Birth month and date, Military affiliation, Social Security Number, Zip code, Driver's License, Internet Protocol (IP) Address,
                <b> Personal Information Listed in the California Customer Records Statute - </b>
                The above identifiers
                Employment information - Employment status, Employer, Length of employment, Job title, Monthly income, pay period and paycheck type, Illness/disability, Work phone number.

                Financial information - Auto status, Own/rent car, Own/rent home, Bank name, Bank account type (checking or savings, Bank account number, Bank routing number, Length at bank, Loan amount, Loan purpose, Debt amounts (including mortgage debt, auto debt unsecured debt, tax debt, secured debt, student loan debt, medical debt, payday loan debt, personal loan debt), Primary income source

                <b>Characteristics of Protected Classifications</b> - Age, Military affiliation
                <b>Commercial Information</b> - Information about your debt history, Information about your assets
                <b>Biometric Information </b>- Not collected
                <b>Internet or Other Electronic Network Activity Information</b> - Cookies, Domain name, Browser type and version, Operating system, Usage data, visit frequency, referring affiliate information, Referring ad campaign, Time on site, Source URL, Redirect URL.

                <b>Geo location Data </b>- Information that tells us from where you access our website
                <b>Sensory Data</b> - Not collected
                <b>Professional or Employment-Related Information</b> - Employment status, Employer, Length of employment, Job title, Monthly income, pay period and paycheck type, Illness/disability
                <b>Non-public Education Information</b> - Degree attained, Education level, Graduation year
                <b>Inferences Drawn from Other Personal Information</b> - Your potential preferences for lending services, debt relief services, or other financial services.

              </p>
            </div>

            <div className={`${styles.parenr_inner}`}>
              <h1 className={`${styles.subtext_small}`}>Categories of Sources of Personal Information</h1>
              <p className={`${styles.para}`}>We collect information from the following categories of sources:</p>
              <ul>
                <li>Directly from you. For example, when you complete online forms or access our Website to utilize our services.</li>
                <li>Third Parties. For example, when we receive data from an aggregator, marketer, lead generator, referring website or advertising campaign.</li>
              </ul>
            </div>




            <div className={`${styles.parenr_inner}`}>
              <h1 className={`${styles.subtext_small}`}>How We Share Your Personal Information</h1>
              <p className={`${styles.para}`}>We share information in each of the above categories as follows:</p>
              <ul>
                <li>All categories of information
                  <ul>
                    <li>We will share information in all of the above categories if our company is sold or we engage in a merger or other such transaction.</li>
                    <li>We will share information in all of the above categories of information in connection with a law enforcement request that is compliant with the California Electronic Communications Privacy Act.
                    </li>
                  </ul>
                </li>
                <li>Identifiers
                  <ul>
                    <li>We share identifiers with service providers who use that information only to provide services to us such as shipping, name and address verification, email distribution and promotions management.</li>
                    <li>We share identifiers with Service Providers such as lenders, financial service providers, debt relief providers, aggregators and other marketers whose services we believe may be of interest to you. To opt-out of this sharing, see how to submit an opt-out request below.</li>
                  </ul>
                </li>

                <li>Personal Information Listed in the California Customer Records Statute
                  <ul>
                    <li>We share this information with Service Providers such as lenders, financial service providers, debt relief providers, aggregators and other marketers whose services we believe may be of interest to you. To opt-out of this sharing, see how to submit an opt-out request below.</li>
                  </ul>
                </li>

                <li>Characteristics of Protected Classifications
                  <ul>
                    <li>We share this information with Service Providers such as lenders, financial service providers, debt relief providers, aggregators and other marketers whose services we believe may be of interest to you. To opt-out of this sharing, see how to submit an opt-out request below.</li>
                  </ul>
                </li>

                <li>Internet or Other Electronic Network Activity Information
                  <ul>
                    <li>We share this information with our data analytics providers</li>
                    <li>We share this information with Service Providers to track referrals and ad campaign activity.</li>
                  </ul>
                </li>

                <li>Professional or Employment-Related Information
                  <ul>
                    <li>We share this information with Service Providers such as lenders, financial service providers, debt relief providers, aggregators and other marketers whose services we believe may be of interest to you. To opt-out of this sharing, see how to submit an opt-out request below.</li>
                  </ul>
                </li>

                <li>Non-public Education Information
                  <ul>
                    <li>We share this information with Service Providers such as lenders, financial service providers, debt relief providers, aggregators and other marketers whose services we believe may be of interest to you. To opt-out of this sharing, see how to submit an opt-out request below.</li>
                  </ul>
                </li>

                <li>Inferences Drawn from Other Personal Information
                  <ul>
                    <li>We share this information with Service Providers such as lenders, financial service providers, debt relief providers, aggregators and other marketers whose services we believe may be of interest to you. To opt-out of this sharing, see how to submit an opt-out request below.</li>
                  </ul>
                </li>


              </ul>
            </div>







            <div className={`${styles.parenr_inner}`}>
              <h1 className={`${styles.subtext_small}`}>How We Use Your Information</h1>
              <p className={`${styles.para}`}>See "What We Do with the Information We Collect" above to learn how we use your information. We will not collect additional categories of personal information or use the personal information we collected for materially different, unrelated, or incompatible purposes without providing you notice.</p>
            </div>



            <div className={`${styles.parenr_inner}`}>
              <h1 className={`${styles.subtext_small}`}>How We Retain and Store Your Personal Information</h1>
              <p className={`${styles.para}`}>We retain your personal information for as long as necessary to fulfil the purpose(s) for which we collected it and to comply with applicable laws. We use reasonable security precautions to protect your information while in storage.</p>
            </div>



            <div className={`${styles.parenr_inner}`}>
              <h1 className={`${styles.subtext_small}`}>Requests to Know</h1>
              <p className={`${styles.para}`}>You have the right to request that we disclose certain information to you about our collection and use of your personal information over the past 12 months. Once we receive and confirm your verifiable consumer request, we will disclose to you:</p>
              <p className={`${styles.para}`}>
                <ul>
                  <li>The categories of personal information we collected about you.</li>
                  <li>The categories of sources for the personal information we collected about you.</li>
                  <li>Our business or commercial purpose for collecting or selling that personal information.</li>
                  <li>The categories of third parties with whom we share that personal information.</li>
                  <li>The specific pieces of personal information we collected about you (also called a data portability request).</li>
                  <li>If we sold or disclosed your personal information for a business purpose, two separate lists disclosing:
                    <ul>
                      <li>sales, identifying the personal information categories that each category of recipient purchased; and</li>
                      <li>disclosures for a business purpose, identifying the personal information categories that each category of recipient obtained.</li>
                    </ul>

                  </li>
                </ul>
              </p>
            </div>

            <div className={`${styles.parenr_inner}`}>
              <h1 className={`${styles.subtext_small}`}>Requests to Delete</h1>
              <p className={`${styles.para}`}>You have the right to request that we delete any of your personal information that we collected from you and retained, subject to certain exceptions. Once we receive and confirm your verifiable consumer request, we will delete (and direct our service providers to delete) your personal information from our records, unless an exception applies.</p>
              <p className={`${styles.para}`}>We may deny your deletion request if retaining the information is necessary for us or our service provider(s) to:</p>
              <ul>
                <li>Complete the transaction for which we collected the personal information, provide a good or service that you requested, take actions reasonably anticipated within the context of our ongoing business relationship with you, or otherwise perform our contract with you.</li>
                <li>Detect security incidents, protect against malicious, deceptive, fraudulent, or illegal activity, or prosecute those responsible for such activities.</li>
                <li>Debug products to identify and repair errors that impair existing intended functionality.</li>
                <li>Enable solely internal uses that are reasonably aligned with consumer expectations based on your relationship with us.</li>
                <li>Comply with a legal obligation.</li>
                <li>Make other internal and lawful uses of that information that are compatible with the context in which you provided it.</li>
              </ul>
            </div>


            <div className={`${styles.parenr_inner}`}>
              <h1 className={`${styles.subtext_small}`}>Non-Discrimination</h1>
              <p className={`${styles.para}`}>We will not discriminate against you for exercising any of your CCPA rights. Unless permitted by the CCPA, we will not:

                <ul>
                  <li>Deny you goods or services.</li>
                  <li>Charge you different prices or rates for goods or services, including through granting discounts or other benefits, or imposing penalties.</li>
                  <li>Provide you a different level or quality of goods or services.</li>
                  <li>Suggest that you may receive a different price or rate for goods or services or a different level or quality of goods or services.</li>
                </ul>
              </p>
            </div>


            <div className={`${styles.parenr_inner}`}>
              <h1 className={`${styles.subtext_small}`}>Authorized Agent</h1>
              <p className={`${styles.para}`}>California residents can empower an "authorized agent" to submit requests on their behalf. We will require the authorized agent to have a written authorization confirming that authority</p>
            </div>


            <div className={`${styles.parenr_inner}`}>
              <h1 className={`${styles.subtext_small}`}>How to Submit Your Request for Specific Information</h1>
              <p className={`${styles.para}`}>You can request access to specific information we have about you by sending an email with the Subject Line "CCPA Right to Know" to <a href="mailto:info@getpersonalloan.net">info@getpersonalloan.net</a>  or you can make your request for information 
              <a href="https://useasycash.com/Form/?c=california&amp;m=ccpa_request&amp;website=getpersonalloan.net" target="_blank"> here </a>. In your request, please provide us with enough information to identify you, including (i) your full name; (ii) physical address; (iii) phone number; (iv) email address; (v) proof of your identity (e.g. driver's license or passport); and (vi) a description of what right you want to exercise. Once your request is submitted, we will reach out to you within the time frame required by law to verify your identity. After we verify your identity, we will send your information to you via an electronic format. Any personal information we collect from you to verify your identity in connection with your request will be used solely for the purposes of verification.</p>
            </div>


            <div className={`${styles.parenr_inner}`}>
              <h1 className={`${styles.subtext_small}`}>How to Exercise Your Right to Delete Information.</h1>
              <p className={`${styles.para}`}>You can request the categories of information we have on file or deletion of information available on our request form available <a href="https://useasycash.com/Form/?c=california&amp;m=ccpa_request&amp;website=getpersonalloan.net" target="_blank"> here </a>. You may also submit this request by sending an email with the Subject Line "CCPA Right to Request or Delete Information" to <a href="mailto:info@getpersonalloan.net">info@getpersonalloan.net</a>. In your request, please provide us with enough information to identify you, including (i) your full name; (ii) physical address; (iii) phone number; (iv) email address; (v) proof of your identity (e.g. driver's license or passport); and (vi) a description of what right you want to exercise. Once your request is submitted, we will reach out to you within the time frame required by law to verify your identity. After we verify your identity, we will send your information to you via an electronic format. Any personal information we collect from you to verify your identity in connection with your request will be used solely for the purposes of verification.</p>
            </div>


            <div className={`${styles.parenr_inner}`}>
              <h1 className={`${styles.subtext_small}`}>How to Exercise Your Right to Opt-Out of Sale of Your Information.</h1>
              <p className={`${styles.para}`}>Under the CCPA, if you are 16 years of age or older, you have the right to direct us not to sell your personal information at any time. You may submit your request through the URL provided below. You may also submit this request by sending an email with the Subject Line "CCPA Do Not Sell My Information" to <a href="mailto:info@getpersonalloan.net">info@getpersonalloan.net</a>.com. In your request, please provide us with enough information to identify you, including (i) your full name; (ii) physical address; (iii) phone number; (iv) email address; (v) proof of your identity (e.g. driver's license or passport); and (vi) a description of what right you want to exercise. Once your request is submitted, we will reach out to you within the time frame required by law to verify your identity. After we verify your identity, we will send your information to you via an electronic format. Any personal information we collect from you to verify your identity in connection with your request will be used solely for the purposes of verification.</p>
            </div>



            <div className={`${styles.parenr_inner}`}>
              <h1 className={`${styles.subtext_small}`}>DO NOT SELL MY PERSONAL INFORMATION</h1>
              <p className={`${styles.para}`}>However, if you opt-out, please note that we will be unable to help you find a loan or other product or services that may be of interest to you through our Network Partners. In order to opt-in, please submit the completed form or resubmit your request, which lets us know that you want to sell your information to deliver the products and services you requested</p>
            </div>



          </div>

        </div>
        <Row>
          <ReadyToRequest />
        </Row>
      </Container>
      <Footer />
    </>
  )
}

export default Sellinfo