import React from "react";
import { Accordion, Container } from "react-bootstrap";
import styles from "../Faqs/faqs.module.css";
import stylesG from "../../GlobalStyle/page.module.css";
import Arrowgreen from "../../../assets/ArrowGreen.svg";

import { Link } from "react-router-dom";
export const Faqs = () => {
   
    return (
        <Container fluid className={`block_padding ${styles.block_bg}`}>

            <div className="container">
                <div className="row row_align">
                    <div className="col-lx-6 col-lg-6 col-md-6 col-sm-12">
                        <div className={`${styles.frist_block_main}`}>

                            <h1 className={`${styles.hedding_tex}`}>
                            Frequently Asked Questions
                            </h1>
                            {/* <p className={`${styles.hedding_subtex}`}>
                                The lenders who are onboarded with us will have their own criteria to determine whether an applicant is eligible for a loan, but there are general requirements borrowers must meet to qualify. You must:
                            </p> */}
                        </div>
                    </div>
                    <div className="col-lx-6 col-lg-6 col-md-6 col-sm-12">
                        <div className={`faq_parent ${styles.waterimg3}`}  >
                        <Accordion flush>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>
                                    <p className={`${styles.faqs_quse_text}`}> What are the requirements to submit a loan?</p>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <p>- Should be employed in your current position for at least 90 days</p>
                                    <p>- Should be older than 18 years of age, should be a US citizen or a permanent
                                        resident</p>
                                    <p>- Should have a minimum income of $1000 each month after tax deductions</p>
                                    <p>- Should have a valid email address, work and home phone number.</p>
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="2">
                                <Accordion.Header>
                                    <p className={`${styles.faqs_quse_text}`}> What amount could I get?</p>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <p>You could be eligible to any amount up to $35000. The approved amount will be determined by the lender. Having a working email address, work telephone number and home telephone are some of the usual prerequisites for getting a loans</p>
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="3">
                                <Accordion.Header>
                                    <p className={`${styles.faqs_quse_text}`}>  How much time does it take? </p>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <p>It could take as few minutes to process the duly filled form. You could get your loans after approval within the next business day.</p>
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="4">
                                <Accordion.Header>
                                    <p className={`${styles.faqs_quse_text}`}>  What is the cost?</p>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <p>There is no cost to use lendfy.net. The fees of the loan will vary depending upon the amount and the lender. The lender will inform you of the fees for the loan which may vary depending on the lender.</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="5">
                                <div className={`${styles.read_text_main}`} >
                                    <Link to="/faqs" className={`${styles.read_text}`}>Read more <img src={Arrowgreen} alt="arrow" className={`${stylesG.img_arrow}`} /> </Link>
                                </div>
                            </Accordion.Item>
                        </Accordion>
                        </div>

                    </div>
                   
                </div>
            </div>
        </Container>
    );
};
