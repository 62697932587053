import React from "react";
import { Container } from "react-bootstrap";
import styles from "../CheckBlock/check.module.css";
import stylesG from "../../GlobalStyle/page.module.css";
import checkimg from "../../../assets/check.svg";
import Arrowblack from "../../../assets/Arrowblack.svg";
export const CheckBlock = (props) => {
  const {handleClick} =props;
  return (
    <Container fluid className={`block_padding ${styles.block_bg}`}>
    
      <div className="container">
        <div className="row row_align">
          <div className="col-lx-6 col-lg-6 col-md-6 col-sm-12">
            <div className={`${styles.frist_block_main}`}>
            
              <h1 className={`${styles.hedding_tex}`}>
              Do I qualify for a personal loan?
              </h1>
              <p className={`${styles.hedding_subtex}`}>
              The lenders who are onboarded with us will have their own criteria to determine whether an applicant is eligible for a loan, but there are general requirements borrowers must meet to qualify. You must:
              </p>
            </div>
          </div>
          <div className="col-lx-6 col-lg-6 col-md-6 col-sm-12">
           <div className={`${styles.sec_block_parant} ${styles.waterimg2}`}>
           <div className={`${styles.sec_block_main}`}>
              <div className={`${styles.steps_img}`}>
                <img src={checkimg} alt="img1" />
              </div>
              <div>
                <h1 className={`${styles.sec_hedding_tex} `}>Be 18 years or older.</h1>
               
              </div>
            </div>

            <div className={`${styles.sec_block_main} ${styles.add_padding}`}>
              <div className={`${styles.steps_img}`}>
                <img src={checkimg} alt="img1" />
              </div>
              <div>
                <h1 className={`${styles.sec_hedding_tex} `}>
                Be a U.S. citizen or permanent resident.
                </h1>
               
              </div>
            </div>

            <div className={`${styles.sec_block_main} ${styles.add_padding}`}>
              <div className={`${styles.steps_img}`}>
                <img src={checkimg} alt="img1" />
              </div>
              <div>
                <h1 className={`${styles.sec_hedding_tex}`}>
                Have a steady source of income.
                </h1>
              
              </div>
            </div>

            <div className={`${styles.sec_block_main} ${styles.add_padding}`}>
              <div className={`${styles.steps_img}`}>
                <img src={checkimg} alt="img1" />
              </div>
              <div>
                <h1 className={`${styles.sec_hedding_tex}`}>
                Have a valid telephone number and email address.
                </h1>
              
              </div>
            </div>

            <div className={`${styles.sec_block_main} ${styles.add_padding}`}>
              <div className={`${styles.steps_img}`}>
                <img src={checkimg} alt="img1" />
              </div>
              <div>
                <h1 className={`${styles.sec_hedding_tex}`}>
                Have a valid bank account for deposits and repayment.
                </h1>
              
              </div>
            </div>

            <div className={`${styles.sec_block_main} ${styles.add_padding}`}>
              <div className={`${styles.steps_img}`}>
                <img src={checkimg} alt="img1" />
              </div>
              <div>
                <h1 className={`${styles.sec_hedding_tex}`}>
                Have a valid Social Security number.
                </h1>
              
              </div>
            </div>

            <div className={`${styles.sec_block_main} ${styles.add_padding}`}>
              <div className={`${styles.steps_img}`}>
                <img src={checkimg} alt="img1" />
              </div>
              <div>
                <h1 className={`${styles.sec_hedding_tex}`}>
                Meet minimum income requirements.
                </h1>
              
              </div>
            </div>

           </div>

          </div>

          
          <div className={`${stylesG.request_btn_main}`} onClick={handleClick}>
            <button className={`${stylesG.request_btn}`}>Check if you qualify
            <img src={Arrowblack} alt="arrow" className={`${stylesG.img_arrow}`}/>
            </button>
          </div>
        </div>
      </div>
    </Container>
  );
};
