// import { Route, Routes } from "react-router-dom";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import Home from "./components/Pages/Home/Home";
import Form from "./components/Form/Form";
import 'animate.css';
import RatesFees from "./components/Pages/RatesFees/RatesFees";
import NotFound from "./components/Pages/NotFound/NotFound";
import Disclaimer from "./components/Pages/Disclaimer/Disclaimer";
import Faq from "./components/Pages/Faqs/Faq";
import PrivacyPolicy from "./components/Pages/PrivacyPolicy/PrivacyPolicy";
import TermService from "./components/Pages/TermsService/TermService";
import ContactUs from "./components/Pages/ContactUs/ContactUs";
import Econsent from "./components/Pages/E-Consent/Econsent";
import Unsubscribe from "./components/Pages/Unsubscribe/Unsubcribe";
import Sellinfo from "./components/Pages/Sellinformation/Sellinfo";
import AboutUs from "./components/Pages/AboutUs/AboutUs";
// import Faq from "./components/Pages/Faqs/Faq";


function App() {


  return (

    <>
      <Router>
        <Routes>
        
          <Route exact path="/" element={<Home />} />
          <Route exact path="/faqs" element={<Faq />} />
          <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route exact path="/term-of-service" element={<TermService />} />
          <Route exact path="/contact-us" element={<ContactUs />} />
          <Route exact path="/rates-fees" element={<RatesFees />} />
          <Route exact path="/disclaimer" element={<Disclaimer />} />
          <Route exact path="/e-consent" element={<Econsent />} />
          <Route exact path="/unsubscribe" element={<Unsubscribe />} />
          <Route exact path="/information" element={<Sellinfo />} />
          <Route exact path="/about-us" element={<AboutUs />} />
         


          <Route path='*' element={<NotFound />} />
          <Route exact path="/form" element={<Form />} />
        </Routes>
      </Router>

    </>

  )
}

export default App;
