import React from 'react'
import { Container } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Footer from '../../Sections/Footer/Footer'
import Header from '../../Sections/Header/Header'
import ReadyToRequest from '../../Sections/ReadyToRequest/ReadyToRequest'
import styles from "../../GlobalStyle/page.module.css"
const AboutUs = () => {
  const Urls = window.location.pathname.substring(1).replace();
  return (
    <>
    <Container fluid>
      <Header />
      <div className={`container ${styles.block_padding}`}>

        <p className={`${styles.urls}`} ><Link to="/">Home {">"}</Link> {Urls} </p>
        <h1 className={`${styles.main_headding}`}>About Us</h1>

        <div className={`${styles.para_parent} ${styles.waterimg8}`}>
          <p className={`${styles.para}`}><b>Getpersonalloan.net</b>is a lending network, not a direct lender. We work with reputable lenders and lending partners to provide a convenient way for you to find a loan that meets your needs. Our services are entirely free, and there are no hidden fees involved in filling out a loan request form. When you submit a loan request through our platform, you will be contacted by one of our lenders or lending partners to confirm your personal information and finalize the loan. Please note that LoanFy does not make credit decisions and is not responsible for the terms and conditions of any loan offered by our partners. Our goal is to make it easy for you to find the right loan option and to help you make informed decisions about borrowing.</p>
        </div>
      </div>
    </Container>
    <ReadyToRequest/>
<Footer/>
</>
  )
}

export default AboutUs