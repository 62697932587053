import React from 'react'
import { Container } from 'react-bootstrap'
import styles from "../ReadyToRequest/ready.module.css"
import Arrowblack from "../../../assets/ArrowGreen.svg";
const ReadyToRequest = (props) => {
  const {handleClick} =props;
  return (
    <Container fluid className={`block_padding ${styles.block_bg}`}>
      <div className="container">
        <h1 className={`${styles.req_hedding}`} >Ready to request<br /> hassle free?</h1>
        <p className={`${styles.req_para}`} >Request today from the comfort of your home, and if the lender approves, you might get the money as soon as the following working day.</p>

        <div className={`${styles.request_btn_main}`} onClick={handleClick}>
            <button className={`${styles.request_btn}`}>Request Hassle Free
            <img src={Arrowblack} alt="arrow" className={` img-fluid ${styles.img_arrow}`}/>
            </button>
          </div>
      </div>
    </Container>
  )
}

export default ReadyToRequest