import React, { useState } from "react";
import { Container } from "react-bootstrap";
import styles from "../FristBlock/fristblock.module.css";
import bannerimg from "../../../assets/banner_img.svg";
import secure1 from "../../../assets/secure1.svg";
import secure2 from "../../../assets/secure2.svg";
import secure3 from "../../../assets/secure3.svg";
import { createSearchParams, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import useQuery from "./useQuery";

const FristBlock = () => {
  // const { ref } = props;
  // Get query Params
  const query = useQuery();
  // const queryName = query.getAll;
  const uid = query.get("utm_source") || "";
  const utm_campaign = query.get("utm_campaign") || "";
  const utm_medium = query.get("utm_medium") || "";
  const s2 = query.get("s2") || "";
  const s3 = query.get("s3") || "";
  const s4 = query.get("s4") || "";

  // Get query Params End //

  const navigate = useNavigate();
  // navigate('/form');

  const { register, handleSubmit, reset } = useForm();

  const [formData, setformData] = useState({});

  //  console.log("Amount",formData);

  const onChange = (data) => {
    console.log(data);

    const myData = {
      ...data,
      uid,
      utm_campaign,
      utm_medium,
      s2,
      s3,
      s4,
    };
    setformData(myData);
    console.log(myData);

    const params = {
      s1: myData.utm_campaign,
      s2: myData.s2,
      s3: myData.s3,
      s4: myData.s4,
      uid: myData.uid,
      utm_medium: myData.utm_medium,
      amount: myData.amount,

    };
    console.log("params", params);

    navigate({
      pathname: "/form",
      search: `?${createSearchParams(params)}`,
    });

    reset();
  };
  console.log("FormData", formData);
  const olaimg = "https://stage.ola-memberseal.org/seal?seal=member&token=LyA33nIJ3rtmadALDCm0"
  return (
    <>

      <Container fluid className="thembg block_padding" style={{ paddingRight: "0px" }}>

        <div className={`container ${styles.paddding_block}`}>
          <div className="row m-0">
            <div className="col-sm-12 col-md-12 col-lg-8 col-xl-8 p-0">
              <div className={`${styles.paddding_left_block}`}>

                {/* <h1 className={`animate__animated animate__fadeInLeft ${styles.headding_frist}`}>
                  How much would you like to carry off?
                </h1> */}
                <h1 className={` ${styles.headding_frist}`}>
                  How much would you like to carry off?
                </h1>
                <p className={`${styles.headding_frist_one}`}>
                  We give you freedom to request up to $35,000
                </p>
                {/* form block */}
                <form onChange={handleSubmit(onChange)}>
                  <div className="radio radio_parent">
                    <label htmlFor="income_type1" className="remove_paddong">
                      <input
                        type="radio"
                        id="income_type1"
                        value="400"
                        {...register("amount")}
                      />
                      <span className="label-bg">$100 - $500</span>
                    </label>
                    <label htmlFor="income_type2" className="remove_paddong">
                      <input
                        type="radio"
                        value="800"
                        id="income_type2"
                        {...register("amount")}
                      />
                      <span className="label-bg">$600 - $1,000</span>
                    </label>
                    <label htmlFor="income_type3" className="remove_paddong">
                      <input
                        type="radio"
                        value="1300"
                        id="income_type3"
                        {...register("amount")}
                      />
                      <span className="label-bg">$1100 - $1500</span>
                    </label>
                    <label htmlFor="income_type4" className="remove_paddong">
                      <input
                        type="radio"
                        value="More"
                        id="income_type4"
                        {...register("amount")}
                      />

                      <span className="label-bg custom_lable">+ Need more</span>
                    </label>
                  </div>
                </form>

                {/* <div className={`${styles.width_inner_input}`}></div> */}

                <div className={`${styles.securelo_parent}`}>
                  <div className={`${styles.securelog_box} `}>
                    <div className={`${styles.secure_img}`}>
                      <img src={secure1} alt="img1" className={`img-fluid ${styles.secure_img1}`} />
                    </div>
                    <p className={`${styles.secure_para}`}>Any Credit type</p>
                  </div>

                  <div className={`${styles.securelog_box}`}>
                    <div className={`${styles.secure_img} ${styles.secure_img_mob}`}>
                      <img src={secure2} alt="img2" className={`img-fluid ${styles.secure_img1}`} />
                    </div>
                    <p className={`${styles.secure_para}`}>Quick decision</p>
                  </div>
                  <div className={`${styles.securelog_box}`}>
                    <div className={`${styles.secure_img} ${styles.secure_img_mob}`}>
                      <img src={secure3} alt="img3" className={`img-fluid ${styles.secure_img1}`} />
                    </div>
                    <p className={`${styles.secure_para}`}>Simple & Secure</p>
                  </div>
                </div>
              </div>
            </div>

            {/*  */}

            <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4 p-0">
              <div className={` ${styles.bannerimg_paraent}`}>
                <img
                  src={bannerimg}
                  alt="bannerimg"
                  className={`img-fluid ${styles.bannerimg}`}
                />
              </div>
            </div>

          </div>
        </div>
      </Container>
      <div className={`${styles.ola_parent}`}>
      <div className={`${styles.ola_main}`}>
        <div className={`${styles.ola_box}`}>
        <a href="https://onlinelendersalliance.org/look-for-the-ola-seal" target="_blank">  <img src={olaimg} className={`${styles.ola_sty}`}/></a>
        
        </div>
        <div className={`${styles.ola_box}`}>
          <p>When you see the <a href="https://onlinelendersalliance.org/look-for-the-ola-seal" target="_blank">{"OLA seal"}</a>, you can be sure that the business you're dealing with is devoted to the highest moral standards, committed to giving their users the greatest experience possible, complying, and making every effort to safeguard customers from fraud.</p>
        </div>
      </div>
      </div>
    </>
  );
};

export default FristBlock;
