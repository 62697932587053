import axios from "axios";

const api_url = "https://useasycash.com/formcdn/v-1.2.1/";
const Api = axios.create({
  baseURL: api_url,
    headers: {
    "Content-Type": "multipart/form-data",
  },
});



export default Api;
